// Copyright © 2023 Move Closer

import { VueConstructor } from 'vue'
import { IconsRegistry } from '@/shared/core/src/front/shared/icons/icons.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export function buildDSLRegistry (): Record<string, VueConstructor> {
  return Object.entries(iconsRegistry).reduce(
    (acc, [iconName, iconDefinition]) => ({
      ...acc,
      [iconName]: iconDefinition.component
    }),
    {}
  )
}

/**
 * Main registry of icons.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const iconsRegistry: IconsRegistry = {
  ArrowDownIcon: {
    label: 'Arrow Down',
    component: () => import(/* webpackChunkName: "icons" */ './ArrowDownIcon.vue')
  },
  ArrowLeftIcon: {
    label: 'Arrow Left',
    component: () => import(/* webpackChunkName: "icons" */ './ArrowLeftIcon.vue')
  },
  ArrowRightIcon: {
    label: 'Arrow Right',
    component: () => import(/* webpackChunkName: "icons" */ './ArrowRightIcon.vue')
  },
  ArrowTopIcon: {
    label: 'Arrow Top',
    component: () => import(/* webpackChunkName: "icons" */ './ArrowTopIcon.vue')
  },
  AttachIcon: {
    label: 'Attach',
    component: () => import(/* webpackChunkName: "icons" */ './AttachIcon.vue')
  },
  CalendarIcon: {
    label: 'Calendar',
    component: () => import(/* webpackChunkName: "icons" */ './CalendarIcon.vue')
  },
  ChatIcon: {
    label: 'Chat',
    component: () => import(/* webpackChunkName: "icons" */ './ChatIcon.vue')
  },
  CheckIcon: {
    label: 'Check',
    component: () => import(/* webpackChunkName: "icons" */ './CheckIcon.vue')
  },
  CircleCheckIcon: {
    label: 'Circle Check',
    component: () => import(/* webpackChunkName: "icons" */ './CircleCheckIcon.vue')
  },
  CircleCloseIcon: {
    label: 'Circle Close',
    component: () => import(/* webpackChunkName: "icons" */ './CircleCloseIcon.vue')
  },
  CircleQuestionIcon: {
    label: 'Circle Question',
    component: () => import(/* webpackChunkName: "icons" */ './CircleQuestionIcon.vue')
  },
  CloseIcon: {
    label: 'Close',
    component: () => import(/* webpackChunkName: "icons" */ './CloseIcon.vue')
  },
  CopyIcon: {
    label: 'Copy',
    component: () => import(/* webpackChunkName: "icons" */ './CopyIcon.vue')
  },
  DangerIcon: {
    label: 'Danger',
    component: () => import(/* webpackChunkName: "icons" */ './DangerIcon.vue')
  },
  DisabledIcon: {
    label: 'Disabled',
    component: () => import(/* webpackChunkName: "icons" */ './DisabledIcon.vue')
  },
  EyeClosedIcon: {
    label: 'Eye Closed',
    component: () => import(/* webpackChunkName: "icons" */ './EyeClosedIcon.vue')
  },
  EyeIcon: {
    label: 'Eye',
    component: () => import(/* webpackChunkName: "icons" */ './EyeIcon.vue')
  },
  FilterIcon: {
    label: 'Filter',
    component: () => import(/* webpackChunkName: "icons" */ './FilterIcon.vue')
  },
  GoogleSignIn: {
    label: 'Facebook Sign-in',
    component: () => import(/* webpackChunkName: "icons" */ './GoogleSignIn.vue')
  },
  HamburgerIcon: {
    label: 'Hamburger',
    component: () => import(/* webpackChunkName: "icons" */ './HamburgerIcon.vue')
  },
  HeartEmptyIcon: {
    label: 'Heart Empty',
    component: () => import(/* webpackChunkName: "icons" */ './HeartEmptyIcon.vue')
  },
  HeartFullIcon: {
    label: 'Heart Full',
    component: () => import(/* webpackChunkName: "icons" */ './HeartFullIcon.vue')
  },
  InfoIcon: {
    label: 'Info',
    component: () => import(/* webpackChunkName: "icons" */ './InfoIcon.vue')
  },
  LabelIcon: {
    label: 'Label',
    component: () => import(/* webpackChunkName: "icons" */ './LabelIcon.vue')
  },
  LoadingIcon: {
    label: 'Loading',
    component: () => import(/* webpackChunkName: "icons" */ './LoadingIcon.vue')
  },
  ManagePreferencesIcon: {
    label: 'Manage Preferences',
    component: () => import(/* webpackChunkName: "icons" */ './ManagePreferencesIcon.vue')
  },
  MinusIcon: {
    label: 'Minus',
    component: () => import(/* webpackChunkName: "icons" */ './MinusIcon.vue')
  },
  NightIcon: {
    label: 'Moon',
    component: () => import(/* webpackChunkName: "icons" */ './NightIcon.vue')
  },
  PlusIcon: {
    label: 'Plus',
    component: () => import(/* webpackChunkName: "icons" */ './PlusIcon.vue')
  },
  PlayIcon: {
    label: 'Play',
    component: () => import(/* webpackChunkName: "icons" */ './PlayIcon.vue')
  },
  PointIcon: {
    label: 'Point',
    component: () => import(/* webpackChunkName: "icons" */ './PointIcon.vue')
  },
  SearchIcon: {
    label: 'Search',
    component: () => import(/* webpackChunkName: "icons" */ './SearchIcon.vue')
  },
  ShoppingCartIcon: {
    label: 'Shopping Cart',
    component: () => import(/* webpackChunkName: "icons" */ './ShoppingCartIcon.vue')
  },
  SilhouetteIcon: {
    label: 'Silhouette',
    component: () => import(/* webpackChunkName: "icons" */ './SilhouetteIcon.vue')
  },
  StarEmptyIcon: {
    label: 'Star Empty',
    component: () => import(/* webpackChunkName: "icons" */ './StarEmptyIcon.vue')
  },
  StarFullIcon: {
    label: 'Star Full',
    component: () => import(/* webpackChunkName: "icons" */ './StarFullIcon.vue')
  },
  StarHalfIcon: {
    label: 'Star Half',
    component: () => import(/* webpackChunkName: "icons" */ './StarHalfIcon.vue')
  },
  DayIcon: {
    label: 'Sun',
    component: () => import(/* webpackChunkName: "icons" */ './DayIcon.vue')
  },
  TrashIcon: {
    label: 'Trash',
    component: () => import(/* webpackChunkName: "icons" */ './TrashIcon.vue')
  },
  UploadIcon: {
    label: 'Upload',
    component: () => import(/* webpackChunkName: "icons" */ './UploadIcon.vue')
  },
  WarningIcon: {
    label: 'Warning',
    component: () => import(/* webpackChunkName: "icons" */ './WarningIcon.vue')
  },
  ZaufaneOpinieCeneoIcon: {
    label: 'ZaufaneOpinieCeneo',
    component: () => import(/* webpackChunkName: "icons" */ './ZaufaneOpinieCeneoIcon.vue')
  },
  ZoomInIcon: {
    label: 'Zoom In',
    component: () => import(/* webpackChunkName: "icons" */ './ZoomInIcon.vue')
  },
  ZoomOutIcon: {
    label: 'Zoom Out',
    component: () => import(/* webpackChunkName: "icons" */ './ZoomOutIcon.vue')
  },

  /**
   * Footer delivery methods icons registry
   */
  DHLIcon: {
    label: 'DHL',
    component: () => import(/* webpackChunkName: "icons" */ './DHLIcon.vue')
  },
  DPDIcon: {
    label: 'DPD',
    component: () => import(/* webpackChunkName: "icons" */ './DPDIcon.vue')
  },
  InPostIcon: {
    label: 'InPost',
    component: () => import(/* webpackChunkName: "icons" */ './InPostIcon.vue')
  },
  OrlenPaczkaIcon: {
    label: 'Orlen Paczka',
    component: () => import(/* webpackChunkName: "icons" */ './OrlenPaczkaIcon.vue')
  },

  /**
   * Footer payment methods icons registry
   */
  ApplePayIcon: {
    label: 'Apple Pay',
    component: () => import(/* webpackChunkName: "icons" */ './ApplePayIcon.vue')
  },
  BlikIcon: {
    label: 'Blik',
    component: () => import(/* webpackChunkName: "icons" */ './BlikIcon.vue')
  },
  GooglePayIcon: {
    label: 'Google Pay',
    component: () => import(/* webpackChunkName: "icons" */ './GooglePayIcon.vue')
  },
  MasterCardIcon: {
    label: 'Master Card',
    component: () => import(/* webpackChunkName: "icons" */ './MasterCardIcon.vue')
  },
  PayPalIcon: {
    label: 'PayPal',
    component: () => import(/* webpackChunkName: "icons" */ './PayPalIcon.vue')
  },
  PayUIcon: {
    label: 'PayU',
    component: () => import(/* webpackChunkName: "icons" */ './PayUIcon.vue')
  },
  PayNowIcon: {
    label: 'PayNow',
    component: () => import(/* webpackChunkName: "icons" */ './PayNowIcon.vue')
  },
  VisaIcon: {
    label: 'Visa',
    component: () => import(/* webpackChunkName: "icons" */ './VisaIcon.vue')
  },

  /**
   * social icons registry
   */
  FacebookIcon: {
    label: 'Facebook',
    component: () => import(/* webpackChunkName: "icons" */ './FacebookIcon.vue')
  },
  InstagramIcon: {
    label: 'Instagram',
    component: () => import(/* webpackChunkName: "icons" */ './InstagramIcon.vue')
  },
  YouTubeIcon: {
    label: 'YouTube',
    component: () => import(/* webpackChunkName: "icons" */ './YouTubeIcon.vue')
  },
  TikTokWhiteIcon: {
    label: 'TikTok - white',
    component: () => import(/* webpackChunkName: "icons" */ './TikTokWhite.vue')
  },
  TikTokBlackIcon: {
    label: 'TikTok - black',
    component: () => import(/* webpackChunkName: "icons" */ './TikTokBlack.vue')
  },

  /**
   * MegaMenu icons
   */
  BazyPodMakijazIcon: {
    label: 'BazyPodMakijazIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/BazyPodMakijaz.vue')
  },
  BedeMamaIcon: {
    label: 'BedeMamaIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/BedeMama.vue')
  },
  DemakijazIcon: {
    label: 'DemakijazIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/DemakijazIcon.vue')
  },
  DlaDzieciIcon: {
    label: 'DlaDzieciIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/DlaDzieciIcon.vue')
  },
  DoOpalaniaIcon: {
    label: 'DoOpalaniaIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/DoOpalaniaIcon.vue')
  },
  KremyIcon: {
    label: 'KremyIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/KremyIcon.vue')
  },
  OchronaPrzeciwslonecznaIcon: {
    label: 'OchronaPrzeciwslonecznaIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/OchronaPrzeciwslonecznaIcon.vue')
  },
  OutletIcon: {
    label: 'OutletIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/OutletIcon.vue')
  },
  PielegnacjaIcon: {
    label: 'PielegnacjaIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/PielegnacjaIcon.vue')
  },
  PielegnacjaTwarzyIcon: {
    label: 'PielegnacjaTwarzyIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/PielegnacjaTwarzyIcon.vue')
  },
  PodkladyIcon: {
    label: 'PodkladyIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/PodkladyIcon.vue')
  },
  PodPrysznicDoKapieliIcon: {
    label: 'PodPrysznicDoKapieliIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/PodPrysznicDoKapieliIcon.vue')
  },
  PoOpalaniuIcon: {
    label: 'PoOpalaniuIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/PoOpalaniuIcon.vue')
  },
  ProduktyAntybakteryjneIcon: {
    label: 'ProduktyAntybakteryjneIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/ProduktyAntybakteryjneIcon.vue')
  },
  PudryIcon: {
    label: 'PudryIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/PudryIcon.vue')
  },
  SamoopalaczeBronzeryIcon: {
    label: 'SamoopalaczeBronzeryIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/SamoopalaczeBronzeryIcon.vue')
  },
  StopCelulitIcon: {
    label: 'StopCelulitIcon',
    component: () => import(/* webpackChunkName: "icons" */ './MegaMenuIcons/StopCelulitIcon.vue')
  }
}
